
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import RoleForm from '../../components/role/RoleForm.vue';
import EditRoleLoader from '@/components/role/EditRoleLoader.vue';
import UserForm from '@/components/user/UserForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { User } from '@/interfaces/models/User';
import StackedForm from '@/mixins/StackedForm';
import UserApiService from '@/api/http/UserApiService';
import ResetPasswordForm from '@/components/user/ResetPasswordForm.vue';
import { Permission } from '@/enums/Permission';

const role = namespace('role');
const user = namespace('user');
const app = namespace('app');
const venueLabel = namespace('venueLabel');

@Component({
  components: { ResetPasswordForm, UserForm, EditRoleLoader, RoleForm, VWrapper },
})
export default class EditUser extends mixins(Editable, Notification, StackedForm) {
  @user.Action('store') public store!: any;
  @user.Action('show') public show!: any;
  @user.Action('update') public update!: any;
  @user.State('active') public active!: User;

  @role.Action('fetch') public getRoles!: any;
  @role.State('items') public roles!: any;

  @app.Action('fetchVenues') public getVenues!: any;
  @app.State('venues') public venues!: Venue[];

  @venueLabel.Action('fetch') public getVenueLabels!: any;
  @venueLabel.State('items') public venueLabels!: VenueLabel[];

  public $refs!: {
    form: InstanceType<typeof UserForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
    passwordForm: InstanceType<typeof UserForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get user() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get title() {
    return this.editing ? 'user.edit' : 'user.create';
  }

  public changePassword() {
    this.$refs.passwordForm.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        const api: UserApiService = new UserApiService();
        await api.changePassword({ id: this.id, ...this.$refs.passwordForm.getData() });
        this.$router.push({ name: 'user.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public async mounted() {
    this.$startLoading('role');
    this.$startLoading('venueLabels');
    await this.getRoles();

    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }

    if (this.editing) {
      this.$startLoading('user');
      await this.show({ id: this.id });
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'user.index' });
    }
    this.$stopLoading('user');
    this.$stopLoading('role');
    this.$stopLoading('venueLabels');
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean) => {
      if (this.isValid(res)) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.user!._id });
        } else {
          await this.store({ ...this.$refs.form.getData() });
        }
        this.$router.push({ name: 'user.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
