var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VWrapper',{attrs:{"title":"user.index","sub-title":_vm.title}},[(_vm.$isLoading('user') || _vm.$isLoading('role') || _vm.$isLoading('venue') || _vm.$isLoading('venueLabels'))?_c('EditRoleLoader'):_vm._e(),(
      !_vm.$isLoading('user') &&
      !_vm.$isLoading('role') &&
      !_vm.$isLoading('venue') &&
      !_vm.$isLoading('venueLabels') &&
      (_vm.active || !_vm.editing)
    )?_c('div',[_c('VRow',{staticClass:"py-4"},[_c('VSpacer'),_c('VBtn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('common.save')))]),_c('VBtn',{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1),_c('VCard',[_c('VCardText',[_c('UserForm',{ref:"form",attrs:{"initial-values":_vm.user,"editing":_vm.editing,"venues":_vm.venues,"labels":_vm.venueLabels,"roles":_vm.roles}})],1)],1),(_vm.editing)?[_c('VRow',{staticClass:"py-4"},[_c('VSpacer'),_c('VBtn',{attrs:{"color":"primary"},on:{"click":_vm.changePassword}},[_vm._v(_vm._s(_vm.$t('user.changePassword')))]),_c('VBtn',{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1),_c('VCard',[_c('VCardText',[_c('ResetPasswordForm',{ref:"passwordForm"})],1)],1)]:_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }